import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

const Dorie = () => (
   <div id="dorie-container" className="">
      
 </div>
  );
  
  export default Dorie;
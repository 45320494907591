import * as React from "react"


import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import NavGlobal from "../components/nav-global"

import { Row } from 'react-bootstrap'
import { Col } from 'react-bootstrap'

const Moe = () => (
    <div id="moe-container" className="">
     <NavGlobal />
    </div>
    
  );
  
  export default Moe;